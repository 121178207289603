<template>
  <div>
    <!-- DESKTOP -->
    <div class="mb-xl-index-news" v-if="!isMobile">
      <v-row justify="center">
        <v-col class="pr-11 pl-11" cols="12">
          <NiewMain class="v-step-7"></NiewMain>
        </v-col>
        <v-col cols="12" class="pl-7">
          <p class="text-title-news ml-4 mb-3">{{ $translate("indexNews.birthday") }}</p>
          <v-alert
            v-if="listB.length == 0"
            dense
            text
            type="info"
            class="my-4 mx-4 text-center"
          >
          {{ $translate("indexNews.noBirthday") }}
          </v-alert>
          <div class="mb-xl-action ml-3 pr-7">
            <v-slide-group
              multiple
              show-arrows
              prev-icon="mdi-chevron-left-circle-outline"
              next-icon="mdi-chevron-right-circle-outline"
            >
              <v-slide-item
                v-for="data in listB"
                :key="data.id"
                v-slot="{ active }"
              >
                <v-card
                  class="mx-2 hover-action mt-3 mb-2"
                  :input-value="active"
                  active-class="purple white--text"
                  style="border-radius: 8px"
                  depressed
                  rounded
                  width="450"
                >
                  <v-row align="center">
                    <v-col class="mt-2 pl-6 pr-3" cols="3">
                      <v-img
                        width="60"
                        height="60"
                        style="
                          border-radius: 50px;
                          border: 2px solid;
                          border-color: #3b5bc0;
                        "
                        :src="data.img"
                      ></v-img>
                    </v-col>
                    <v-col class="pt-6 pb-4 pl-0 pr-5 text-left" cols="9">
                      <p
                        class="text-blue text-1-1-lines text-left mb-0 pb-0"
                        style="color: #353535"
                      >
                        {{ data.full_name }}
                      </p>
                      <p class="text-1-lines mb-0 pb-0" style="color: #626262">
                        {{ data.profile.name }}
                      </p>
                    </v-col>
                  </v-row>
                </v-card>
              </v-slide-item>
            </v-slide-group>
          </div>
        </v-col>
        <v-col class="pl-11" cols="9">
          <Niews class="v-step-8"></Niews>
        </v-col>
        <v-col class="pl-0 pr-11" cols="3">
          <p class="text-title-news ml-4 mb-7">{{ $translate("indexNews.BRMFlash") }}
          </p>
          <Flash class="v-step-9"></Flash>
        </v-col>
      </v-row>
    </div>
    <!-- MOBILE -->
    <div class="mb-sm-index-news" v-else>
      <v-row class="px-0 mx-0 mt-6">
        <v-col style="text-align: left" cols="12" class="pt-2 px-3 index-f-sm">
          <div class="d-flex">
            <v-btn @click="$router.go(-1)" x-small class="mt-0 mb-2" fab icon>
              <img width="15" src="../../assets/icon/navigate_before.png" />
            </v-btn>
            <p class="text-title-1 text-1-lines">
              <label
                style="padding-top: 5%"
                class="text-apps-blue-sm txt-capitalize"
              >
                {{ name }},
              </label>
              <label style="padding-top: 5%" class="text-apps-blue-sm">
                actualicese con las
              </label>
            </p>
          </div>
          <p style="text-align: left" class="text-apps-sm mb-1 ml-9">
            Últimas novedades
          </p>
        </v-col>
        <v-col cols="12" class="v-step-mobile-19">
          <Flash></Flash>
        </v-col>
        <v-col cols="12" class="v-step-mobile-20">
          <Niews></Niews>
        </v-col>
        <v-col class="mb-15 pb-6 mt-0 mt-0 pt-0 pt-0" cols="12">
          <p class="index-news-blue v-step-mobile-21">
            Hoy están de cumpleaños
          </p>
          <v-alert
            v-if="listB.length == 0"
            dense
            text
            type="info"
            class="my-4 mx-4 text-center"
          >
            ¡Hoy nadie esta cumpliendo años!
          </v-alert>
          <div v-for="data in listB" :key="data.id">
            <v-row class="pb-1">
              <v-col cols="3">
                <img class="img-profile-n ml-3" :src="data.img" alt="foto" />
              </v-col>
              <v-col cols="9">
                <p class="index-news-name pb-0 mb-0 text-1-1-lines">
                  {{ data.full_name }}
                </p>
                <p class="index-news-dependence pb-0 mb-0 text-1-1-lines">
                  {{ data.profile.name }}
                </p>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </div>

    <v-overlay :value="loadingAll">
      <v-progress-circular :size="120" color="#466BE3" indeterminate>
        {{ $translate("general.loading") }}
      </v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import NiewMain from "./NewsMain.vue";
import Niews from "./NewsGrid.vue";
import Flash from "./Flash.vue";
import Api from "@/utils/api";

import {
  Hooper,
  Slide,
  Pagination as HooperPagination,
  Navigation as HooperNavigation,
} from "hooper";
import "hooper/dist/hooper.css";

export default {
  components: {
    Niews,
    Flash,
    NiewMain,
    Hooper,
    Slide,
    HooperPagination,
    HooperNavigation,
  },
  data() {
    return {
      loadingAll: false,
      name: "",
      infoToken: {
        document: "",
      },
      listB: [],
      isMobile: false
    };
  },
  methods: {
    checkIfMobile() {
      this.isMobile = window.innerWidth <= 1023;
    },
    getToken() {
      var token = localStorage.getItem("token");
      var div = token.split(".");
      let currentUser = this.$store.getters.getUser;
      this.currentUser = currentUser;
      console.log(this.currentUser,"cumple")

      if (div.length >= 2) {
        this.infoToken = JSON.parse(atob(div[1]));

        var nameList = this.infoToken.fullName.split(" ");

        if (nameList.length > 1) {
          if (nameList[0] != "") {
            this.name = nameList[0];
          } else {
            this.name = nameList[1];
          }
        } else {
          this.name = "";
        }
      } else {
        this.infoToken = {
          document: "",
        };
      }
    },
    getCurrent() {
      if (this.$store.getters.getUser) {
        this.currentUser = this.$store.getters.getUser;
        this.getHappy();
      }
    },
    slidePrev() {
      this.$refs.carousel.slidePrev();
    },
    slideNext() {
      this.$refs.carousel.slideNext();
    },
    getHappy() {
      this.loadingAll = true;
      var dataC = {
        pais: this.currentUser.country,
      };
      Api.noAuth()
        .getBirth(dataC)
        .then((res) => {
          this.loadingAll = false;
          if (res.data.cod == 0) {
            res.data.data.forEach((element) => { 
              // let img = element.img.startsWith("https://app")
              //   ? "https://duvapi.tars.dev/storage/images/IW5XYkbqmQWGLXE81N4kqjfHnfHoWeKxST5A8tfU.png"
              //   : element.img;
              let data = {
                img:"https://duvapi.tars.dev/storage/images/IW5XYkbqmQWGLXE81N4kqjfHnfHoWeKxST5A8tfU.png",
                full_name: this.toTitleCase(element.full_name),
                profile: {
                  name: this.toTitleCase(element.profile.name.split("|")[0]),
                },
              };
              this.listB.push(data);
            });
          }
        })
        .catch((error) => {
          this.loadingAll = false;
          console.log(error);
        }).finally(() => {
          this.loadingAll = false;
        });;
    },
    toTitleCase(str) {
      return str
        .toLowerCase()
        .split(" ")
        .map(function (word) {
          return word.charAt(0).toUpperCase() + word.slice(1);
        })
        .join(" ");
    },
  },
  created() {
    this.checkIfMobile();
    this.getToken();
    window.addEventListener('resize', this.checkIfMobile);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkIfMobile);
  },
  mounted() {
    this.getCurrent();
  },
  watch: {
    "$store.state.user": {
      handler(newValue, oldValue) {
        this.getCurrent();
      },
    },
  },
};
</script>
<style>
@import "./../../assets/css/main.less";
.mdi-chevron-right-circle-outline::before {
  /* content: "\F0B2A"; */
  color: #466be3;
}

.mdi-chevron-left-circle-outline::before {
  /* content: "\F0B28"; */
  color: #466be3;
}

.v-icon--disabled::before {
  color: #c6c6c6 !important;
}

.text-apps-blue-sm {
  font-weight: 300;
  font-size: 25px;
  line-height: 30px;
  color: #466be3;
}
.text-title-news {
  text-align: left;
  font-weight: 300;
  font-size: 23px;
  line-height: 37px;
  color: #353535;
  font-family: "RobotoRegular";
}
.text-apps-sm {
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  color: #626262;
  font-family: "RobotoRegular";
}

.img-profile-n {
  border: 2px solid;
  border-radius: 104px;
  border-color: #3b5bc0;
  height: 50px;
  width: 50px;
}

.index-news-blue {
  font-weight: 300;
  font-size: 23px;
  line-height: 38px;
  color: #466be3;
  font-family: "RobotoRegular";
}

.index-news-name {
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #141b33;
  font-family: "RobotoRegular";
}

.ligth-text {
  color: #fff;
}

.mb-sm-index-news {
  display: none;
}

.v-slide-group__next,
.v-slide-group__prev {
  justify-content: center !important;
  min-width: 28px !important;
  flex: 0 1 24px !important;
}
.index-news__birthday {
  max-width: 100%;
  overflow: hidden;
}

/* LAPTOP 4k */

@media (max-width: 1900px) {
}

/* LAPTOP LARGE */

@media (max-width: 1600px) {
}

/* LAPTOP LARGE */

@media (max-width: 1400px) {
}

/* LAPTOP NORMAL */

@media (max-width: 1200px) {
}

/* TABLET */

@media (max-width: 1023px) {
  .mb-xl-index-news {
    display: none;
  }
  .mb-sm-index-news {
    display: block;
    text-align: initial;
  }
  .text-tag-sm {
    font-style: italic;
    font-weight: 400;
    font-size: 12px;
    line-height: 11px;
    font-family: "RobotoRegular";
  }
  .v-slide-group__next,
  .v-slide-group__prev {
    justify-content: center !important;
    min-width: 33px !important;
    flex: 0 1 24px !important;
  }
}

/* MOBILE L*/
@media (max-width: 767px) {
  .mb-xl-index-news {
    display: none;
  }
  .mb-sm-index-news {
    display: block;
    text-align: initial;
  }
  .text-tag-sm {
    font-style: italic;
    font-weight: 400;
    font-size: 12px;
    line-height: 11px;
    font-family: "RobotoRegular";
  }
  .v-slide-group__next,
  .v-slide-group__prev {
    justify-content: center !important;
    min-width: 33px !important;
    flex: 0 1 24px !important;
  }
}

/* MOBILE M */
@media (max-width: 375px) {
  .mb-xl-index-news {
    display: none;
  }
  .mb-sm-index-news {
    display: block;
    text-align: initial;
  }
  .text-tag-sm {
    font-style: italic;
    font-weight: 400;
    font-size: 12px;
    line-height: 11px;
    font-family: "RobotoRegular";
  }
  .v-slide-group__next,
  .v-slide-group__prev {
    justify-content: center !important;
    min-width: 33px !important;
    flex: 0 1 24px !important;
  }
}

/* MOBILE S */
@media (max-width: 320px) {
  .mb-xl-index-news {
    display: none;
  }
  .mb-sm-index-news {
    display: block;
    text-align: initial;
  }
  .text-tag-sm {
    font-style: italic;
    font-weight: 400;
    font-size: 12px;
    line-height: 11px;
    font-family: "RobotoRegular";
  }
  .v-slide-group__next,
  .v-slide-group__prev {
    justify-content: center !important;
    min-width: 33px !important;
    flex: 0 1 24px !important;
  }
}
</style>
